.cal-container {
  width: 324px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .cal-container {
    width: 100%;
  }
}
