.content1-wrapper {
  padding: 64px 24px;
  .content1 {
    height: 100%;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        width: 250px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0 32px;
      height: 100%;
      .content1-content,
      .content1-title {
        position: relative !important;
      }
      .content1-title {
        font-size: 32px;
        font-weight: normal;
        color: #404040;
      }
      .content {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content1-wrapper {
    padding: 0 24px 32px;
    .content1 {
      &-img {
        padding: 0;
        text-align: center;
        span {
          display: inline-block;
          width: 180px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .content1-content,
        .content1-title {
          width: 100%;
          top: auto;
        }
        .content1-title {
          margin: 0 auto 16px;
          font-size: 24px;
        }
      }
    }
  }
}
