@use 'variables' as *;

.content3 {
  &-wrapper {
    min-height: 564px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #f7f9fc;

    &.home-page-wrapper {
      .home-page {
        padding: 64px 100px;
      }
    }
  }

  &-title2 {
    &-wrapper {
      text-align: center;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  &-title {
    &-wrapper {
      text-align: center;
      margin-bottom: 40px;
    }

    &-h1 {
      font-size: 32px;
      color: $text-color;
    }

    &-content {
      margin-top: 16px;
    }
  }

  &-block {
    margin-top: 28px;

    &-group {
      display: block;
      padding: 28px 24px;
      box-shadow: 0 2px 16px fade(#000, 8);
      background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
      background-repeat: no-repeat;
      background-position: 100% 100%;
      transition: box-shadow $animate-duration $ease-in-out,
        transform $animate-duration $ease-in-out;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 16px fade(#000, 12);
      }
    }

    &-image {
      float: left;
      width: 24px;
    }

    &-title {
      font-size: 14px;
      float: left;
      margin-left: 8px;
      margin-bottom: 16px;
      color: $text-color;
    }

    &-content {
      clear: both;
      color: fade($text-color, 75);
    }
  }

  &-calculators-container {
    padding-left: 32px;
  }
}

@media screen and (max-width: 768px) {
  .content3-wrapper {
    min-height: 1540px;
    &.home-page-wrapper {
      .home-page {
        padding: 56px 24px;
      }
    }
  }
  .content3-calculators-container {
    padding-left: 0px;
  }
}
