@use 'variables' as *;

.abovefooter {
  &-wrapper {
    min-height: 564px;
    margin: 0 auto;
    overflow: hidden;
  }

  &-logo {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;

    & img {
      vertical-align: middle;
      display: inline-block;
    }

    & a {
      display: block;
    }
  }

  &-title2 {
    &-wrapper {
      text-align: center;
      margin-bottom: 16px;
      margin-top: 40px;
    }

    &-h1 {
      font-size: 32px;
      color: $text-color;
    }
  }

  &-title3 {
    &-wrapper {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 120px;
    }

    &-h1 {
      font-size: 32px;
      color: $text-color;
    }
  }

  &-title {
    &-wrapper {
      text-align: center;
      margin-bottom: 40px;
    }

    &-h1 {
      font-size: 32px;
      color: $text-color;
    }

    &-content {
      margin-top: 16px;
    }
  }

  &-block {
    margin-top: 28px;

    &-image {
      float: left;
      width: 24px;
    }

    &-title {
      font-size: 14px;
      float: left;
      margin-left: 8px;
      margin-bottom: 16px;
      color: $text-color;
    }

    &-content {
      clear: both;
      color: fade($text-color, 75);
    }
  }

  &-ratings {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .abovefooter-wrapper {
    &.home-page-wrapper {
      .home-page {
        padding: 56px 24px;
      }
    }
  }
}
