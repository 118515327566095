.authPage {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 10px 0;
  background: #fafafb;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  height: 221px;
  width: 685px;
  text-align: center;
  background-position-x: center;
}
.errorsContainer {
  margin-top: -21px;
  margin-bottom: 18px;
  color: #ff203c;
}
.headerContainer {
  > span {
    line-height: 36px;
    font-size: 24px;
    font-weight: 600;
  }
  > img {
    margin-top: 1px;
    height: 40px;
  }
}
.headerDescription {
  width: 416px;
  text-align: center;
  margin: auto;
  padding: 13px 30px 0 30px;
  line-height: 18px;
  color: #333740;
  font-size: 14px;
}

.formContainer {
  min-height: 200px;
  width: 416px;
  margin: 14px auto;
  margin-bottom: 0;
  padding: 33px 15px 15px 15px;
  border-radius: 2px;
  border-top: 2px solid #1c5de7;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #e3e9f3;
}

.loginButton {
  margin-top: -6px;
  padding-right: 0;
  text-align: right;

  > button {
    margin-right: 16px;
  }
}

.linkContainer {
  padding-top: 18px;
  > a {
    color: #262931;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: 0;
    }
  }
}

.linksContainer {
  div.socialLinkContainer:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 16px;
  }
  div.socialLinkContainer:not(:first-child) {
    margin-top: 16px;
  }
}

.logoContainer {
  position: absolute;
  left: 30px;
  bottom: 30px;

  > img {
    height: 34px;
  }
}

.google {
  border: 1px solid #d6d9dc;
}

.facebook {
  background: #4167b2;
}

.login-form {
  width: 100%;
}
