/* Styles copied for compatibility with Ant Design 4.0.0

// Media queries breakpoints

// 👇 Small screen / tablet */
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

$black: #000;
/* color palettes */
$blue-base: #1890ff;

$blue-6: $blue-base;

$text-color: fade($black, 85%);

$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);

/* >>> Primary */
$base-primary: $blue-6;

/* ========= Primary Color ========= */
$ant-primary-color: $base-primary;

$primary-color: $ant-primary-color;

/*  Copied Styles End */

$border-radius-base: 2px;
$font-size-base: 16px;

$line-color: #e9e9e9;

$shadow-color: rgba(0, 0, 0, 0.15);

$bottom-bar-bg-color: #262626;
$bottom-bar-line-color: #000;

$typography-title-font-weight: 400;

$template-bg-color: #001529;
$template-bg-color-light: #ececec;
$template-nav-bg-color: #001529;
$template-text-color: #ccc;
$template-text-title-color: #bcbcbc;
$template-text-color-light: #fff;
$template-footer-text-color: #999;

$animate-duration: 0.45s;
