@use 'variables' as *;

.commentSection {
  &-wrapper {
    margin: 0 auto;
    overflow: hidden;

    &.home-page-wrapper {
      .home-page {
        padding: 24px 100px;
      }
    }
  }

  &-title2 {
    &-wrapper {
      text-align: center;
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }

  &-title {
    &-wrapper {
      text-align: center;
      margin-bottom: 24px;
    }

    &-h1 {
      font-size: 32px;
      color: $text-color;
    }

    &-content {
      margin-top: 16px;
    }
  }

  &-block {
    margin-top: 28px;

    &-title {
      font-size: 14px;
      float: left;
      margin-left: 8px;
      margin-bottom: 16px;
      color: $text-color;
    }

    &-content {
      clear: both;
      color: fade($text-color, 75);
    }
  }
}

@media screen and (max-width: 768px) {
  .commentSection-wrapper {
    &.home-page-wrapper {
      .home-page {
        padding: 56px 24px;
      }
    }
  }
}
